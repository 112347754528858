@use '@content' as *;
@use '@queries' as *;
@use '@typography' as *;
@use '@fixed-size' as *;

.pet__item-same-items {
  @include mainContentStyles;
  padding: 12px 14px;
  display: flex;
  flex-direction: column;
  gap: 14px;
  width: 100%;

  @media (min-width: 428px) {
    padding: 14px;
  }

  @media (min-width: 744px) {
    padding: 0;
    box-shadow: none;
    grid-area: 1 / 2 / 3 / 3;
    gap: 16px;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .label {
      span:has(svg),
      svg {
        color: #6941c6;
      }
    }
  }

  .items {
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-height: 296px;

    @media (min-width: 428px) {
      gap: 10px;
    }
  }
}

.same-items__sheet-header-container {
  display: flex;
  align-items: center;
  gap: 8px;

  @media (min-width: 428px) {
    gap: 12px;
  }
}

.same-items__sheet-container {
  display: flex;
  flex-direction: column;
  max-height: 254px;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  gap: 6px;

  @include hideScrollbar;

  @media (min-width: 428px) {
    gap: 8px;
  }

  @media (min-width: 744px) {
    min-width: 400px;
  }

  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.sheet__shopping-card-add-to-cart {
  @media (min-width: 744px) {
    width: 48px;
    height: 48px;
  }

  span[id='add-to-cart'] {
    @media (min-width: 744px) {
      width: 22px;
      height: 22px;
    }
  }
}

.see-all-btn {
  height: 32px;

  @media (min-width: 428px) {
    height: 40px;
  }

  @media (min-width: 744px) {
    height: 36px;
  }
}

.not-found-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  @include fixedSize(226px, 100%);
  align-self: center;

  .title {
    @include typography-m;
    @include weight-semi-bold;
    color: var(--cst-side-bar-shop-text);

    @include min-428-break {
      @include typography-xl;
    }

    @include min-744-break {
      @include typography-base;
    }
  }

  .subtitle {
    text-align: center;
    @include typography-s;
    @include weight-medium;

    color: var(--cst-side-bar-shop-descriprion);

    @include min-428-break {
      @include typography-m;
    }

    @include min-744-break {
      @include typography-s;
    }
  }
}
