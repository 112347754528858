@use '@queries' as *;
@use '@content' as *;

.search-block,
.content {
  @include mainContentStyles;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 12px 14px;

  @include min-428-break {
    padding: 14px;
    gap: 12px;
  }

  @include min-744-break {
    padding: 14px 28px;
  }

  .input-search {
    > div {
      border-radius: var(--cornerradius10);

      @include min-428-break {
        border-radius: var(--cornerradius12);
      }
    }
  }
}

.search-block {
  position: sticky;
  top: 0;
  z-index: 101;
}

.content {
  gap: 16px;

  @include min-428-break {
    gap: 18px;
  }

  * > div[data-testid='virtuoso-item-list'] {
    display: flex;
    flex-direction: column;
    gap: 16px;

    @include min-428-break {
      gap: 18px;
    }
    div[data-index] {
      display: flex;
      flex-direction: column;
      gap: 16px;

      @include min-428-break {
        gap: 18px;
      }
    }
  }
}
