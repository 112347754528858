@use '@queries' as *;
@use '@fixed-size' as *;

.item {
  z-index: 2;
  position: relative;
  right: -32px;
  @include singleFixedSize(90px);

  @include min-428-break {
    right: -36px;
    top: -10px;
    @include singleFixedSize(124px);
  }

  @include min-744-break {
    right: -16px;
    top: -8px;
  }
}

.item-image {
  z-index: 1;
  position: absolute;
  pointer-events: none;
  bottom: -30px;
  right: -20px;

  @include min-428-break {
    bottom: -45px;
    right: -20px;
  }

  @include min-744-break {
    bottom: -38px;
    right: 15px;
  }
}
