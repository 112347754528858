@use '@/shared/ui/button/adaptive' as *;
@use '@fixed-size' as *;
@use '@typography' as *;
@use '@queries' as *;

.action-btn {
  display: flex;
  flex: 1;
  width: 100%;
  @include buttonSizes('38', '46', '52', '52', '52', '52');

  span.feature-icon,
  svg {
    @include singleFixedSize(18px);

    @include min-428-break {
      @include singleFixedSize(20px);
    }

    @include min-428-break {
      @include singleFixedSize(22px);
    }
  }
}
