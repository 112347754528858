@use '../../app/queries' as *;

@mixin mainContentStyles {
  border-radius: var(--cornerradius8);
  box-shadow: var(--elevation-1);
  background: var(--cst-content-background);

  @include min-428-break {
    border-radius: var(--cornerradius14);
  }

  @include min-744-break {
    border-radius: var(--cornerradius16);
  }
}

@mixin mainContainerStyles {
  display: flex;
  width: 100%;
  height: 100%;
  max-width: 1023px;
  flex-direction: column;

  gap: 12px;

  @include min-428-break {
    gap: 14px;
  }

  @include min-744-break {
    gap: 16px;
  }
}

@mixin hideScrollbar {
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &:hover {
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

@mixin containerPaddings {
  padding: 12px 0;

  @include min-428-break {
    padding: 14px 0;
  }

  @include min-744-break {
    padding: 16px 0;
  }
}
