@use '@content' as *;
@use '@queries' as *;
@use '@tabs' as *;
@use '@typography' as *;
@use '@fixed-size' as *;

.item-block-props {
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media (min-width: 744px) {
    grid-area: 2 / 2 / 3 / 3;
  }
}

.pet__item-properties-wrapper {
  @include mainContentStyles;
  padding: 12px 14px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  margin-top: 12px;

  @include min-428-break {
    margin-top: 14px;
  }
  @include min-744-break {
    margin-top: 0;
  }

  @media (min-width: 428px) {
    padding: 14px;
    gap: 14px;
  }

  @media (min-width: 744px) {
    padding: 0;
    box-shadow: none;
  }

  .pet__item-properties,
  .pet__item-age {
    display: flex;
    flex-direction: column;
    gap: 6px;

    @include min-428-break {
      gap: 12px;
    }
  }
}

.tabs-container {
  display: flex;
  align-items: center;
  gap: 6px;
  width: 100%;
  flex-wrap: wrap;

  @include min-428-break {
    gap: 8px;
  }

  .tab-item {
    @include tabItemSizes('36', '44', '36', '36', '36', '36');
    padding: 7px 12px;
    border-radius: var(--cornerradius10);

    p {
      @include typography-s;
      @include weight-semi-bold;

      @include min-428-break {
        @include typography-base;
      }
      @include min-744-break {
        @include typography-s;
      }
    }

    @include min-428-break {
      border-radius: var(--cornerradius10);

      span:has(svg),
      svg {
        @include singleFixedSize(18px);
      }
    }
  }
}

.icon {
  color: var(--global-orange600);
  @include singleFixedSize(16px);

  @include min-428-break {
    @include singleFixedSize(20px);
  }
}

.props-title {
  font-size: 14px;
  @include weight-semi-bold;

  @media (min-width: 428px) {
    font-size: 16px;
  }

  @media (min-width: 744px) {
    font-size: 14px;
  }
}

.skeleton {
  @include fixedSize(100%, 100px);
  border-radius: var(--cornerradius10);

  @include min-428-break {
    @include fixedSize(100%, 74px);
  }

  @include min-428-break {
    @include fixedSize(100%, 64px);
  }
}
