@use '@queries' as *;
@use '@typography' as *;
@use '@fixed-size' as *;
@use '@content' as *;
@use '@button' as *;

.container {
  &-content {
    display: flex;
    flex-direction: column;
    @include mainContentStyles;
    padding: 12px 14px;
    gap: 8px;
    @include min-428-break {
      gap: 14px;
    }
    @include min-744-break {
      padding: 14px 28px;
    }
    &-buttons {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 8px;
      @include min-744-break {
        gap: 14px;
        grid-template-columns: 1fr 1fr 1fr;
      }
    }
  }
}

.modal {
  max-height: calc(100% - env(safe-area-inset-top)) !important;
  height: calc(100% - env(safe-area-inset-top)) !important;
  border-radius: 0 !important;

  > div {
    border-radius: 0 !important;
  }

  @include min-744-break {
    height: max-content !important;
    width: 428px;
    border-radius: 16px !important;
  }
}

.star-big {
  transform: translate(-55px, -40px) !important;
  @include min-428-break {
    transform: translate(-65px, -55px) !important;
  }
}

.star-small {
  transform: translate(-40px, -40px) !important;
  @include min-428-break {
    transform: translate(-65px, -55px) !important;
  }
}
