@use '@button' as *;

.btn {
  @include buttonSizes('32', '40', '40', '32', '40', '40');
}

.verify-btn {
  pointer-events: auto;
  &-disabled {
    pointer-events: none;
  }
}
