@use '@content' as *;
@use '@queries' as *;

.pet__item-content {
  @include mainContentStyles;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  -moz-border-radius-topleft: 0;
  -moz-border-radius-topright: 0;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  gap: 0px;
  width: 100%;
  align-items: center;
  padding: 12px;
  @media (min-width: 428px) {
    padding: 14px;
  }

  @media (min-width: 744px) {
    padding: 0;
    box-shadow: none;
    margin: 0px;
    grid-area: 2 / 1 / 3 / 2;
    align-self: center;
  }
}

.skeleton {
  aspect-ratio: 292 / 197; //@include fixedSize(292px, 197px);
  @include min-428-break {
    aspect-ratio: 400 / 268; //@include fixedSize(400px, 268px);
    padding: 10px;
    border-radius: var(--cornerradius14);
  }

  @include min-744-break {
    aspect-ratio: 340 / 230; //@include fixedSize(340px, 230px);
    max-width: 340px;
    max-height: 230px;
    padding: 10px;
  }
}
