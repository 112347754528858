@use '@queries' as *;
.content {
  @include min-744-break {
    min-width: 428px;
  }
}
.buttons {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 6px;
  @include min-428-break {
    gap: 8px;
  }
}
