@use '../../../app/queries' as *;
@use '../../../shared/styles/fixed' as *;
@use '../../../shared/ui/typography/sizes' as *;
@use 'sass:map';

$height: (
  '32': 32px,
  '34': 34px,
  '36': 36px,
  '42': 42px,
  '44': 44px,
  '46': 46px,
  '46quaternary': 46px,
);

$font-sizes: (
  '32': var(--font-size-small),
  '34': var(--font-size-small),
  '36': var(--font-size-base),
  '42': var(--font-size-base),
  '44': var(--font-size-base),
  '46': var(--font-size-base),
  '46quaternary': var(--font-size-medium),
);

$gap: (
  '32': 6px,
  '34': 8px,
  '36': 8px,
  '42': 8px,
  '44': 8px,
  '46': 8px,
  '46quaternary': 8px,
);

$radius: (
  '32': 6px,
  '34': 6px,
  '36': 8px,
  '42': 8px,
  '44': 10px,
  '46': 10px,
  '46quaternary': 10px,
);

@mixin tabItemSizes(
  $size320,
  $size428,
  $size744,
  $size1024,
  $size1440,
  $size1920
) {
  min-height: map.get($height, $size320);
  gap: map.get($gap, $size320);
  border-radius: map.get($radius, $size320);
  p {
    font-size: map.get($font-sizes, $size320);
  }

  @include min-428-break {
    min-height: map.get($height, $size428);
    gap: map.get($gap, $size428);
    border-radius: map.get($radius, $size428);
    p {
      font-size: map.get($font-sizes, $size428);
    }
  }

  @include min-744-break {
    min-height: map.get($height, $size744);
    gap: map.get($gap, $size744);
    border-radius: map.get($radius, $size744);
    p {
      font-size: map.get($font-sizes, $size744);
    }
  }

  @include min-1024-break {
    min-height: map.get($height, $size1024);
    gap: map.get($gap, $size1024);
    border-radius: map.get($radius, $size1024);
    p {
      font-size: map.get($font-sizes, $size1024);
    }
  }

  @include min-1440-break {
    min-height: map.get($height, $size1440);
    gap: map.get($gap, $size1440);
    border-radius: map.get($radius, $size1440);
    p {
      font-size: map.get($font-sizes, $size1440);
    }
  }

  @include min-1920-break {
    gap: map.get($gap, $size1920);
    border-radius: map.get($radius, $size1920);
    p {
      font-size: map.get($font-sizes, $size1920);
    }
  }
}
