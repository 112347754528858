@use '@content' as *;
@use '@queries' as *;
@use '@typography' as *;

.description {
  display: flex;
  flex-direction: column;
  gap: 12px;
  box-shadow: var(--elevation-1);

  @media (min-width: 428px) {
    gap: 14px;
  }

  @media (min-width: 744px) {
    display: grid;
    width: 100%;
    grid-template-columns: minmax(340px, 1fr) minmax(324px, 1fr);
    grid-template-rows: 75px minmax(181px, 1fr);
    column-gap: 24px;
    row-gap: 16px;
  }
}

.pet__item-description {
  @include mainContentStyles;
  padding: 12px 14px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media (min-width: 428px) {
    padding: 14px;
    gap: 12px;
  }

  @media (min-width: 744px) {
    padding: 0 14px;
    box-shadow: none;
    grid-area: 2 / 1 / 3 / 2;
  }

  .label {
    span:has(svg),
    svg {
      color: #6941c6;
      fill: #6941c6;
    }
  }

  .text {
    color: var(--crystal-bell900);
    @include typography-s;
    @include weight-medium;

    @include min-428-break {
      @include typography-base;
    }
  }
}

.props-title {
  font-size: 14px;
  font-weight: 600;

  @media (min-width: 428px) {
    font-size: 16px;
  }

  @media (min-width: 744px) {
    font-size: 14px;
  }
}
