@use '@fixed-size' as *;
@use '@typography' as *;
@use '@content' as *;
@use '@queries' as *;

.items-block {
  display: flex;
  flex-direction: column;
  gap: 0px;
  width: 100%;

  @media (min-width: 428px) {
    gap: 0px;
  }

  @media (min-width: 744px) {
    display: grid;
    width: 100%;
    grid-template-columns: minmax(340px, 340px) minmax(324px, 1fr);
    grid-template-rows: 52px minmax(248px, 1fr);
    column-gap: 24px;
    row-gap: 12px;
    padding: 14px 28px;
    @include mainContentStyles;
  }
}

.pet__item-price-diagram {
  @include mainContentStyles;
  padding: 12px 14px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media (min-width: 428px) {
    padding: 14px;
    gap: 14px;
  }

  @media (min-width: 744px) {
    padding: 0;
    box-shadow: none;
    grid-area: 1 / 1 / 2 / 2;
  }

  .label {
    span:has(svg),
    svg {
      color: #6941c6;
    }
  }
}

.diagram {
  display: flex;
  gap: 12px;
  width: 100%;

  @media (min-width: 428px) {
    gap: 14px;
  }

  @media (min-width: 744px) {
    grid-area: 1 / 1 / 2 / 2;
  }
}

.price-statistics__sheet {
  &-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-bottom: 12px;

    @media (min-width: 428px) {
      margin-bottom: 14px;
    }

    > div {
      max-width: 100%;

      @media (min-width: 744px) {
        min-width: 428px;
      }
    }
  }

  span[id='btn-icon'],
  svg {
    @include singleFixedSize(18px);

    @media (min-width: 428px) {
      @include singleFixedSize(20px);
    }

    @media (min-width: 744px) {
      @include singleFixedSize(22px);
    }
  }
}
